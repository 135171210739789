/**
 * App.js
 *
 * @author Dima Dodonov <dimadodonov@icloud.com>
 */

//Global Vars
const $window = $(window);
const $document = $(document);
const $html = $('html');
const $wrapper = $('.wrapper');
const $header = $('.header');
const $main = $('.main');
const $overlay = $('.js-overlay');

const App = {
    init() {
        // console.log('Site init');
        this.header();
        this.menu();
        this.slider();
        this.form();
        this.popup();
        this.fancybox();
        this.yandexMap();
        this.Components.init();
    },

    header() {},
    menu() {
        $('.header-btn-nav').on('click', function () {
            $('.hamburger__menu').toggleClass('animate');
            $('.header-nav').toggleClass('on');
            // $('.header-contact').toggleClass('on');
            $('body').toggleClass('is-fixed');
        });
    },
    slider() {
        var swiper = new Swiper('.partners-slider', {
            slidesPerView: 2,
            spaceBetween: 50,
            freeMode: false,
            loop: true,
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                    freeMode: false,
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                    freeMode: false,
                },
                1024: {
                    slidesPerView: 6,
                    spaceBetween: 70,
                    freeMode: true,
                },
            },
        });

        var swiper = new Swiper('.works-slider', {
            slidesPerView: 'auto',
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    freeMode: true,
                },
                640: {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                    freeMode: true,
                },
            },
        });
    },
    form() {},
    popup() {
        $('.jsCallBack').on('click', function () {
            $('.popup').show();
            $('.popup__overlay').show();
        });

        $('.popup__close').on('click', function () {
            $('.popup').hide();
            $('.popup__overlay').hide();
        });

        $('.popup__overlay').on('click', function () {
            $('.popup').hide();
            $('.popup__overlay').hide();
        });
    },
    fancybox() {
        $('[data-fancybox]').fancybox({
            touch: false,
            // toolbar: false,
            hash: false,
            clickSlide: false,
            clickOutside: 'close',
            buttons: [
                //"zoom",
                //"share",
                //"slideShow",
                //"fullScreen",
                //"download",
                //"thumbs",
                'close',
            ],
            protect: false, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ
            mobile: {
                clickContent: 'close',
                clickSlide: 'close',
            },
            lang: 'ru',
            i18n: {
                ru: {
                    CLOSE: 'Закрыть',
                    NEXT: 'Следующий',
                    PREV: 'Предыдущий',
                    ERROR: 'Ошибка',
                },
            },
        });

        if ($('.wp-block-gallery')) {
            $('.blocks-gallery-item').click(function () {
                var galleryImages = $(this).parent().find('a');
                var gallery = [];

                galleryImages.each(function (index, galleryItem) {
                    var caption = $(this).parent().find('figcaption')
                        ? $(this).find('img').attr('alt')
                        : $(this).parent().find('figcaption');

                    gallery.push({
                        src: galleryItem.href,
                        opts: {
                            caption: caption,
                        },
                    });
                });

                $.fancybox.open(
                    gallery,
                    {
                        touch: false,
                        // toolbar: false,
                        hash: false,
                        clickSlide: false,
                        clickOutside: 'close',
                        buttons: [
                            //"zoom",
                            //"share",
                            //"slideShow",
                            //"fullScreen",
                            //"download",
                            //"thumbs",
                            'close',
                        ],
                        protect: false, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ
                        mobile: {
                            clickContent: 'close',
                            clickSlide: 'close',
                        },
                        lang: 'ru',
                        i18n: {
                            ru: {
                                CLOSE: 'Закрыть',
                                NEXT: 'Следующий',
                                PREV: 'Предыдущий',
                                ERROR: 'Ошибка',
                            },
                        },
                    },
                    $(this).index()
                );

                return false;
            });
        }
    },
    yandexMap() {
        if ($('.map').length) {
            ymaps.ready(init);
        }

        function init() {
            var myMap = new ymaps.Map(
                'map',
                {
                    center: [45.06115957458474, 38.9645155],
                    zoom: 16,
                    controls: ['zoomControl', 'fullscreenControl'],
                },
                {
                    searchControlProvider: 'yandex#search',
                }
            );

            myMap.geoObjects.add(
                new ymaps.Placemark(
                    [45.06115957458474, 38.9645155],
                    {
                        // Чтобы балун и хинт открывались на метке, необходимо задать ей определенные свойства.
                        balloonContentHeader: 'ООО «РусЭкоСтандарт»',
                        balloonContentBody:
                            'Адрес: 350051, г. Краснодар, ул. Дальняя 39/5, оф. 290',
                        balloonContentFooter:
                            'тел: 8(861)945-32-32, 8(962)873-15-77',
                        hintContent: 'ООО «РусЭкоСтандарт»',
                    },
                    {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref:
                            '/wp-content/themes/res/assets/files/icons/svg/icon--map.svg',
                        // Размеры метки.
                        iconImageSize: [50, 62.16],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-25, -58],
                    }
                )
            );
        }
    },
};

App.define = function (namespace) {
    var parts = namespace.split('.'),
        parent = App,
        i;

    //Убрать начальный префикс если это являетсся глобальной переменной
    if (parts[0] == 'App') {
        parts = parts.slice(1);
    }

    //Если в глобальном объекте нет свойства - создать его.
    for (var i = 0; i < parts.length; i++) {
        if (typeof parent[parts[i]] == 'undefined') {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }
    return parent;
};

/**

 * Components.js

 *

 * @author Dima Dodonov <dimadodonov@icloud.com>

 */



App.define('Components');



App.Components = (function () {

    function _init() {

        console.log('Site Components Init');

        // _initInstaFeed();

        // App.Components.Input.init();

    }



    // function _initInstaFeed() {

    //     _initInstaFeed();

    // }



    return {

        init: _init,

        // instaFeed: _initInstaFeed

    };

})();



App.define('Components.Input');

App.Components.Input = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initMask();

    }



    function _initMask() {

        //Masked inputmask https://github.com/RobinHerbots/Inputmask

        let phoneMask = new Inputmask('+7 (999) 999-99-99');

        let inputPhone = document.querySelector('.js-phone-mask');

        if (inputPhone) {

            phoneMask.mask(inputPhone);

        }

    }



    return {

        init: _init,

        initMask: _initMask,

    };

})();



// Убрать сплешь после знака равно

//=/include components/InstaFeed.js



$(function () {
    App.init();
});
